import request from '@/utils/request'

export function add(data) {
  return request({
    url: 'api/news',
    method: 'post',
    data
  })
}

export function del(id) {
  return request({
    url: 'api/news/' + id,
    method: 'delete'
  })
}
export function publi(id) {
    return request({
      url: 'api/news/publish/' + id,
      method: 'post'
    })
  }
export function edit(data) {
  return request({
    url: 'api/news',
    method: 'put',
    data
  })
}