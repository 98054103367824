<template>
  <div>
    <el-dialog :append-to-body="true" :visible.sync="dialog" :title="isAdd ? '新增通告管理':'修改通告管理'" :close-on-click-modal="false" @closed="cancel" width="860px">
      <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="116px" label-suffix=":" v-if="form" :disabled="disabled">
        <div class="h sb">
          <el-form-item label="计划发布日期" prop="begDate">
            <el-date-picker v-model="form.begDate" value-format="timestamp" style="width: 240px" />
          </el-form-item>
          <el-form-item label="计划结束日期" prop="endDate">
            <el-date-picker v-model="form.endDate" value-format="timestamp" style="width: 240px" />
          </el-form-item>
        </div>
        <el-form-item label="标题" prop="title">
          <el-input v-model="form.title" :maxlength="20" />
        </el-form-item>
        <el-form-item label="标题图片" prop="titleImage">
          <file-uploader v-model="form.titleImage" :entity-id="form.fileId" folder="annunciate/images" :thumbnail-size="80"></file-uploader>
        </el-form-item>
        <el-form-item label="发布范围" prop="tagArea">
          <quick-select v-model="form.tagArea" url="api/shop" value-field="id" display-field="shopName" placeholder="全部" filterable multiple style="width: 100%" />
        </el-form-item>
        <el-form-item label="发布内容" prop="content">
          <el-input type="textarea" v-model="form.content" :rows="5" resize="none" maxlength="250" />
        </el-form-item>

        <div class="h sb">
          <form-info-item label="状态" v-if="form.status">
            <div v-if="form.status =='publish'">已发布</div>
            <div v-else-if="form.status =='edit'">编辑中</div>
          </form-info-item>
          <form-info-item label="创建人" v-if="form.editBy">{{form.editBy}}</form-info-item>
          <form-info-item label="创建时间" v-if="form.editAt">{{new Date(form.editAt).format()}}</form-info-item>
        </div>
        <div class="h sb">
          <form-info-item label="发布时间" v-if="form.publishAt">{{new Date(form.publishAt).format()}}</form-info-item>
          <form-info-item label="发布人" v-if="form.publishBy">{{form.publishBy}}</form-info-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="text" @click="dialog = false">取消</el-button>
        <el-button :loading="loading" type="primary" @click="doSave">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { add, edit } from "@/api/annunciate";

export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    }
  },
  mounted() {},
  data() {
    return {
      loading: false,
      dialog: false,
      disabled: false,
      form: {
        begDate: null,
        endDate: null,
        title: null,
        tagArea: [],
        content: null,
        titleImage: null,
        fileId: this.$uuid(),

        status: null,
        editBy: null,
        editAt: null,
        publishAt: null,
        publishBy: null
      },
      rules: {
        title: [{ required: "true", message: "请输入标题", trigger: "blur" }],
        begDate: [{ required: true, message: "请选择计划发布日期" }],
        endDate: [{ required: true, message: "请选择计划结束日期" }]
      }
    };
  },
  methods: {
    cancel() {
      this.resetForm();
    },
    convertForm() {
      let form = JSON.parse(JSON.stringify(this.form));
      if (form.tagArea && form.tagArea.length) {
        form.tagArea = form.tagArea.join(",");
      } else {
        form.tagArea = "all";
      }
      return form;
    },
    doSave() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.loading = true;
          (this.isAdd ? add : edit)(this.convertForm())
            .then(res => {
              this.resetForm();
              this.$notify({
                title: "保存成功",
                type: "success",
                duration: 2500
              });
              this.$parent.init();
            })
            .finally(_ => {
              this.loading = false;
            });
        }
      });
    },
    resetForm(form) {
      this.dialog = false;
      this.disabled = false;
      if (form) {
        if (form.tagArea == "all") {
          form.tagArea = [];
        } else {
          form.tagArea = (form.tagArea || "").split(",").map(Number);
        }
        if (form.fileId == null) {
          form.fileId = this.$uuid();
        }
        this.form = form;
      } else {
        this.form = {
          begDate: null,
          endDate: null,
          title: null,
          tagArea: null,
          content: null,
          titleImage: null,
          fileId: this.$uuid(),
          status: null,
          editBy: null,
          editAt: null,
          publishAt: null,
          publishBy: null
        };
      }
    }
  }
};
</script>

